import Vue from 'vue';
import store from './vuex/store'
import router from './router.js';
import App from './App.vue';
import 'vue-awesome/icons';
import Icon from 'vue-awesome/components/Icon';
import VueGtag from "vue-gtag";

Vue.component('icon', Icon)

// Googleアナリスティックの設定（t.odaアカウントで管理している）
if (process.env.NODE_ENV === 'production') {
	Vue.use(VueGtag, {
		config: { id: process.env.VUE_APP_GA4_ID }
	}, router);
}

var app = new Vue({
	el: '#app',
	store,
	router,
	render: h => h(App)
})
